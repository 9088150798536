import { RadioButtonValgModel } from '../../Shared/Scripts/Components/RadioButtonValg';
import VeilederFristerDto from '../Models/VeilederFristerDto';

const finnmark = 'ba1-finnmark';
const laerer = 'ba2-laerer'
const lege = 'ba3-lege';
const ufoer = 'ba4-ufoer';

const bcSlettingGjeldValg: RadioButtonValgModel<VeilederFristerDto>[] = [
    {
        id: 'frister-bc1',
        value: finnmark,
        tekst: 'steg2SlettingGjeldValgALedetekst',
    },
    {
        id: 'frister-bc2',
        value: laerer,
        tekst: 'steg2SlettingGjeldValgBLedetekst',
    },
    {
        id: 'frister-bc3',
        value: lege,
        tekst: 'steg2SlettingGjeldValgDLedetekst',
    },
    {
        id: 'frister-bc4',
        value: ufoer,
        tekst: 'steg2SlettingGjeldValgCLedetekst',
    },
];

export default {
    bc1_finnmark: finnmark,
    bc2_laerer: laerer,
    bc3_lege: lege,
    bc4_ufoer: ufoer,
    bcSlettingGjeldValg,
};