import ReduxThunk from 'redux-thunk';
import { createStore, combineReducers, Store, applyMiddleware } from 'redux';
import VeilederFristerDto from '../Models/VeilederFristerDto';
import { epiContextReducer } from '../../Shared/Scripts/Store/EpiContext/Reducers';
import { resultatReducer } from './Resultat/Reducers';
import { EpiContext, UpdateEpiContextActionTypes } from '../../Shared/Scripts/Store/EpiContext/Types';
import { stegVisningReducer } from '../../Shared/Scripts/Store/StegVisning/Reducers';
import { EpiAppStateMedSteg } from '../../Shared/Scripts/Store/Types';
import { valgReducer } from './Valg/Reducers';

const rootReducer = combineReducers<VeilederFristerAppState>({
    valg: valgReducer,
    resultat: resultatReducer,
    epiContext: (
        state: EpiContext<VeilederFristerDto> | undefined,
        action: UpdateEpiContextActionTypes<VeilederFristerDto>,
    ): EpiContext<VeilederFristerDto> => epiContextReducer(state, action),
    stegVisning: stegVisningReducer,
});

export type VeilederFristerAppState = EpiAppStateMedSteg<VeilederFristerDto> & {
    valg: ReturnType<typeof valgReducer>;
    resultat: ReturnType<typeof resultatReducer>;
};

export default function configureStore(): Store<VeilederFristerAppState> {
    return createStore(rootReducer, applyMiddleware(ReduxThunk));
}
