import { RadioButtonValgModel } from '../../Shared/Scripts/Components/RadioButtonValg';
import VeilederFristerDto from '../Models/VeilederFristerDto';

const betalingsutsettelse = 'ba1-betalingsutsettelse';
const fastrente = 'ba2-fastrente'
const betalingsfrist = 'ba3-betalingsfrist';

const bbBetaleGjeldValg: RadioButtonValgModel<VeilederFristerDto>[] = [
    {
        id: 'frister-bb1',
        value: fastrente,
        tekst: 'steg2BetaleGjeldValgBLedetekst',
    },
    {
        id: 'frister-bb2',
        value: betalingsutsettelse,
        tekst: 'steg2BetaleGjeldValgALedetekst',
    },
    {
        id: 'frister-bb3',
        value: betalingsfrist,
        tekst: 'steg2BetaleGjeldValgCLedetekst',
    },
];

export default {
    bb1_fastrente: fastrente,
    bb2_betutsettelse: betalingsutsettelse,
    bb3_betfrist: betalingsfrist,
    bbBetaleGjeldValg,
};