export type ResultatValg = ResultatValgVisning;

export interface ResultatValgVisning {
    visBoksBa: boolean;
    visBoksBb: boolean;
    visBoksBc: boolean;
    visBoksCa: boolean;
    visBoksCb: boolean;
    visBoksCc: boolean;
    visBoksCd: boolean;
    visBoksCe: boolean;
    visBoksCf: boolean;
    visBoksCg: boolean;
    visBoksCh: boolean;
    visBoksCi: boolean;
    visBoksCj: boolean;
    visBoksCk: boolean;
    visBoksCl: boolean;
    visBoksCm: boolean;
    visBoksCn: boolean;
}

export const SET_VISNING = 'SET_VISNING';

export interface SetVisningAction {
    type: typeof SET_VISNING;
    payload: Partial<ResultatValgVisning>;
}

export type ResultatActionTypes = SetVisningAction;
