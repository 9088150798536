import { ResultatValg, SET_VISNING, ResultatActionTypes } from './Types';
import { ValgStateActionTypes } from '../Valg/Types';

const initialState: ResultatValg = {
    visBoksBa: false,
    visBoksBb: false,
    visBoksBc: false,
    visBoksCa: false,
    visBoksCb: false,
    visBoksCc: false,
    visBoksCd: false,
    visBoksCe: false,
    visBoksCf: false,
    visBoksCg: false,
    visBoksCh: false,
    visBoksCi: false,
    visBoksCj: false,
    visBoksCk: false,
    visBoksCl: false,
    visBoksCm: false,
    visBoksCn: false,
};

export function resultatReducer(
    state: ResultatValg = initialState,
    action: ValgStateActionTypes | ResultatActionTypes,
): ResultatValg {
    switch (action.type) {
        case SET_VISNING: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
}
