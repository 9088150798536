import { RadioButtonValgModel } from '../../Shared/Scripts/Components/RadioButtonValg';
import VeilederFristerDto from '../Models/VeilederFristerDto';

const uniOgHoyskole = 'ba1-uniOgHoyskole';
const folkehoyskole = 'ba2-folkehoyskole'
const fagskole = 'ba3-fagskole';
const laerling = 'ba4-laerling';
const foreldrestipend = 'ba5-foreldrestipend';
const sykestipend = 'ba6-sykestipend';
const nedsattFunksjonsEvne = 'b7-nedsattFunksjonsEvne';
const endringOgAvbrudd = 'b8-endringOgAvbrudd';

const baStipendOgLanValg: RadioButtonValgModel<VeilederFristerDto>[] = [
    {
        id: 'frister-ba1',
        value: uniOgHoyskole,
        tekst: 'steg2StipendOgLanValgALedetekst',
    },
    {
        id: 'frister-ba2',
        value: folkehoyskole,
        tekst: 'steg2StipendOgLanValgBLedetekst',
    },
    {
        id: 'frister-ba3',
        value: fagskole,
        tekst: 'steg2StipendOgLanValgCLedetekst',
    },
    {
        id: 'frister-ba4',
        value: laerling,
        tekst: 'steg2StipendOgLanValgDLedetekst',
    },
    {
        id: 'frister-ba5',
        value: foreldrestipend,
        tekst: 'steg2StipendOgLanValgELedetekst',
    },
    {
        id: 'frister-ba6',
        value: sykestipend,
        tekst: 'steg2StipendOgLanValgFLedetekst',
    },
    {
        id: 'frister-ba7',
        value: nedsattFunksjonsEvne,
        tekst: 'steg2StipendOgLanValgGLedetekst',
    },
    {
        id: 'frister-ba8',
        value: endringOgAvbrudd,
        tekst: 'steg2StipendOgLanValgHLedetekst',
    },
];

export default {
    ba1_uniOgHoyskole: uniOgHoyskole,
    ba2_folkehoyskole: folkehoyskole,
    ba3_fagskole: fagskole,
    ba4_laerling: laerling,
    ba5_foreldrestipend: foreldrestipend,
    ba6_sykestipend: sykestipend,
    ba7_nedsattFunksjonsEvne: nedsattFunksjonsEvne,
    ba8_endringOgAvbrudd: endringOgAvbrudd,
    baStipendOgLanValg,
};