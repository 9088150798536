import React, {useEffect, useState} from 'react';
import { ModalCompontent } from '../../Shared/Scripts/Components/ModalComponent';
import { ArticleContentComponent } from '../../Shared/Scripts/Components/ArticleContentComponent';
import { updateLiveMessage } from '../../Shared/Scripts/Utilities/LiveMessage';

interface ResultatCProps {
    sporsmalId: string,
    tittel: JSX.Element | null,
    
    stotteSvarfelt_1?: JSX.Element | null,
    svarfelt_1?: JSX.Element | null,
    stotteUnderTittel_1?: JSX.Element | null,

    overskriftSvarfelt_2?: JSX.Element | null,
    stotteSvarfelt_2?: JSX.Element | null,
    svarfelt_2?: JSX.Element | null,
    stotteUnderTittel_2?: JSX.Element | null,

    callToActionLenke?: string | null,
    callToActionLenkeTekst?: string | null,

    standaloneLenke?: string | null,
    standaloneLenkeTekst?: string | null,

    fritekst?: JSX.Element | null,

    modalTekst?: JSX.Element | null,
    modalTittel?: JSX.Element | null,
    modalLenkeTekst?: JSX.Element | null,
    modalAriaTekst?: string,
}

export default function UtregningFb(props: ResultatCProps): JSX.Element {
    const [visModal, setModal] = useState(false);

    useEffect(() => {
        updateLiveMessage();
    }, []);
    
    function onModallick(): void {
        setModal(true);
    }

    function onRequestLukkModal(): void {
        setModal(false);
    }
    return (
        <div id={props.sporsmalId} className="c-veileder__panel c-veileder__panel--resultat c-veileder__panel--aubergine">
            <div className="c-veileder__panel-row">
                <div className="c-veileder__content c-veileder__content--header">
                    <h4 className="c-veileder__tittel c-veileder__tittel--resultat">
                        {props.tittel}
                    </h4>
                    <div className="c-veileder__content-vertical--textarea"><p></p></div>
                </div>  
            </div>
            {props.svarfelt_1 !== null && props.svarfelt_1 !== undefined &&(
                <div className="c-veileder__panel-row c-veileder__content--mini-area-half">
                    <div className="c-veileder__content c-veileder__content--indented">
                        <div className="c-veileder__resultat-tekst-overskrift">{props.stotteSvarfelt_1}</div>
                        <div className="c-veileder__underresultat-tekst">
                            {props.svarfelt_1}
                        </div>
                        <p className="c-veileder__tekst c-veileder__tekst--tett">
                            {props.stotteUnderTittel_1}
                        </p>
                    </div>
                </div>
            )}
            {props.overskriftSvarfelt_2 !== null && props.overskriftSvarfelt_2 !== undefined && (
                <div className="c-veileder__panel-row">
                <div className="c-veileder__content c-veileder__content--header">
                    <h4 className="c-veileder__tittel c-veileder__tittel--resultat">
                        {props.overskriftSvarfelt_2}
                    </h4>
                    <div className="c-veileder__content-vertical--textarea"><p></p></div>
                </div>  
            </div>
            )}
            {props.svarfelt_2 !== null && props.svarfelt_2 !== undefined && (
                <div className="c-veileder__panel-row c-veileder__content--mini-area-half">
                    <div className="c-veileder__content c-veileder__content--indented">
                        <div className="c-veileder__resultat-tekst-overskrift">{props.stotteSvarfelt_2}</div>
                        <div className="c-veileder__underresultat-tekst">
                            {props.svarfelt_2}
                        </div>
                        <p className="c-veileder__tekst c-veileder__tekst--tett">
                            {props.stotteUnderTittel_2}
                        </p>
                    </div>
                </div>
            )}
            {props.fritekst !== null && props.fritekst !== undefined &&(
                <div className="c-veileder__panel-row c-veileder__content--mini-area">
                    <div className="c-veileder__content c-veileder__content--textarea">
                        {props.fritekst}
                    </div>
                </div>
            )}
            {props.callToActionLenke !== null && props.callToActionLenke !== undefined &&(
                <div className="c-veileder__panel-row c-veileder__content--mini-area">
                    <div className="c-veileder__content c-veileder__cta">
                        <a className="c-knapp c-knapp--cta c-knapp--lenke-aubergine c-knapp--tertiary" href={props.callToActionLenke === null ? '' : props.callToActionLenke}>{props.callToActionLenkeTekst}</a>
                    </div>
                </div>
            )}
            {props.standaloneLenke !== null && props.standaloneLenke !== undefined && (
                <div className="c-veileder__panel-row">
                    <div className="c-veileder__content c-veileder__content--mini-area">
                        <a className="c-knapp c-knapp--lenke c-knapp--artikkellenke c-knapp--icon-after c-knapp--pil-hoyre-hvit" href={props.standaloneLenke === null ? '' : props.standaloneLenke}>{props.standaloneLenkeTekst}</a>
                    </div>
                </div>
            )}
            {props.modalLenkeTekst !== null && props.modalLenkeTekst !== undefined && props.modalAriaTekst !== undefined && props.modalTittel !== undefined && props.modalTekst !== undefined &&(
                <div className="c-veileder__panel-row">
                    <div className="c-veileder__content c-veileder__content--indented">
                        <button type="button" className="c-knapp c-knapp--lenke c-knapp--lenke-aubergine" onClick={onModallick}>
                            {props.modalLenkeTekst}
                        </button>
                        <ModalCompontent
                            isOpen={visModal}
                            onRequestClose={onRequestLukkModal}
                            ariaCloseText={props.modalAriaTekst}
                        >
                            <ArticleContentComponent
                                headerinnhold={props.modalTittel}
                                contentinnhold={props.modalTekst}
                            />
                        </ModalCompontent>
                    </div>
                </div>
            )}
        </div>
    )
}