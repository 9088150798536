import {
    ValgStateActionTypes,
    ValgState,
    UPDATE_VALG,
} from './Types';

import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { VeilederFristerAppState } from '..';
import { AnyAction } from 'redux';
import { setVisning } from '../Resultat/Actions';
import ATypeValg from '../../Utilities/A-TypeValg';
import BaSvarValg from '../../Utilities/Ba-SvarValg';
import BbSvarValg from '../../Utilities/Bb-SvarValg';
import BcSvarValg from '../../Utilities/Bc-SvarValg';

export function updateValg(newValg: Partial<ValgState>): ValgStateActionTypes {
    return {
        type: UPDATE_VALG,
        payload: newValg,
    };
}

export const updateVisning = (): ThunkAction<void, VeilederFristerAppState, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<VeilederFristerAppState, {}, AnyAction>, getState: () => VeilederFristerAppState): void => {
        const currentState = getState();
        const visBoksBa = visBa(currentState);
        const visBoksBb = visBb(currentState);
        const visBoksBc = visBc(currentState);
        const visBoksCa = visCa(currentState);
        const visBoksCb = visCb(currentState);
        const visBoksCc = visCc(currentState);
        const visBoksCd = visCd(currentState);
        const visBoksCe = visCe(currentState);
        const visBoksCf = visCf(currentState);
        const visBoksCg = visCg(currentState);
        const visBoksCh = visCh(currentState);
        const visBoksCi = visCi(currentState);
        const visBoksCj = visCj(currentState);
        const visBoksCk = visCk(currentState);
        const visBoksCl = visCl(currentState);
        const visBoksCm = visCm(currentState);
        const visBoksCn = visCn(currentState);
        
        dispatch(
            setVisning({
                visBoksBa,
                visBoksBb,
                visBoksBc,
                visBoksCa,
                visBoksCb,
                visBoksCc,
                visBoksCd,
                visBoksCe,
                visBoksCf,
                visBoksCg,
                visBoksCh,
                visBoksCi,
                visBoksCj,
                visBoksCk,
                visBoksCl,
                visBoksCm,
                visBoksCn,
            }),
        );
    };
};

const visBa = (state: VeilederFristerAppState): boolean => {
    return state.valg.aType?.value === ATypeValg.a1_stipendOgLan ? true : false;
};

const visBb = (state: VeilederFristerAppState): boolean => {
    return state.valg.aType?.value === ATypeValg.a2_betalingAvGjeld ? true : false;
};

const visBc = (state: VeilederFristerAppState): boolean => {
    return state.valg.aType?.value === ATypeValg.a4_ordningerForSlettingAvGjeld ? true : false;
};

const visCa = (state: VeilederFristerAppState): boolean => {
    return state.valg.bSvar?.value === BaSvarValg.ba1_uniOgHoyskole || 
    state.valg.bSvar?.value === BaSvarValg.ba2_folkehoyskole ||
    state.valg.bSvar?.value === BaSvarValg.ba3_fagskole ? true : false;
};

const visCb = (state: VeilederFristerAppState): boolean => {
    return state.valg.bSvar?.value === BaSvarValg.ba4_laerling ? true : false;  
};

const visCc = (state: VeilederFristerAppState): boolean => {
    return state.valg.bSvar?.value === BaSvarValg.ba5_foreldrestipend ? true : false;  
};

const visCd = (state: VeilederFristerAppState): boolean => {
    return state.valg.bSvar?.value === BaSvarValg.ba6_sykestipend ? true : false;  
};

const visCe = (state: VeilederFristerAppState): boolean => {
    return state.valg.bSvar?.value === BaSvarValg.ba7_nedsattFunksjonsEvne ? true : false;  
};

const visCf = (state: VeilederFristerAppState): boolean => {
    return state.valg.bSvar?.value === BaSvarValg.ba8_endringOgAvbrudd ? true : false;  
};

const visCg = (state: VeilederFristerAppState): boolean => {
    return state.valg.bSvar?.value === BbSvarValg.bb1_fastrente ? true : false;  
};

const visCh = (state: VeilederFristerAppState): boolean => {
    return state.valg.bSvar?.value === BbSvarValg.bb2_betutsettelse ? true : false;  
};

const visCi = (state: VeilederFristerAppState): boolean => {
    return state.valg.bSvar?.value === BbSvarValg.bb3_betfrist ? true : false;  
};

const visCj = (state: VeilederFristerAppState): boolean => {
    return state.valg.aType?.value === ATypeValg.a3_slettingAvRenter ? true : false;  
};

const visCk = (state: VeilederFristerAppState): boolean => {
    return state.valg.bSvar?.value === BcSvarValg.bc1_finnmark ? true : false;  
};

const visCl = (state: VeilederFristerAppState): boolean => {
    return state.valg.bSvar?.value === BcSvarValg.bc2_laerer ? true : false;  
};

const visCm = (state: VeilederFristerAppState): boolean => {
    return state.valg.bSvar?.value === BcSvarValg.bc3_lege ? true : false;  
};

const visCn = (state: VeilederFristerAppState): boolean => {
    return state.valg.bSvar?.value === BcSvarValg.bc4_ufoer ? true : false;  
};

