import { ValgStateActionTypes, ValgState, UPDATE_VALG } from './Types';

export const initialState: ValgState = {
    aType: undefined,
    bSvar: undefined,
};

export function valgReducer(state: ValgState = initialState, action: ValgStateActionTypes): ValgState {
    const newState = getNewState();
    return newState;

    function getNewState(): ValgState {
        switch (action.type) {
            case UPDATE_VALG: {
                if (action.payload.aType) {
                    return {
                        ...initialState,
                        ...action.payload,
                    };
                } else {
                    return {
                        ...state,
                        ...action.payload,
                    };
                }
            }
            default:
                return state;
        }
    }
}
