import React from 'react';
import { Provider } from 'react-redux';
import App from './App';
import configureStore from './Store';
import InitEpiMessages from '../Shared/Scripts/Utilities/EpiMessages';
import { updateLanguage } from '../Shared/Scripts/Store/EpiContext/Actions';
import { epiResolveLanguage } from '../Shared/Scripts/Utilities/EpiResolveLanguage';
import { AppContainerId } from './Models/Ids';
import { createRoot } from 'react-dom/client';

const store = configureStore();
store.dispatch(updateLanguage(epiResolveLanguage()));

const container = document.getElementById(AppContainerId);

if (container != null) {
    const root = createRoot(container!);
    const contentLink = container.attributes['data-content'].value as string;
    InitEpiMessages(store, contentLink);

    const AppRoot = (): JSX.Element => (
        <Provider store={store}>
            <App />
        </Provider>
    );

    root.render(<AppRoot />);
}
