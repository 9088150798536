import Valg from '../../../Shared/Scripts/Models/Valg';

export interface ValgState {
    aType?: Valg<string>;
    bSvar?: Valg<string>;
}

export const UPDATE_VALG = 'UPDATE_VALG';

interface UpdateValgAction {
    type: typeof UPDATE_VALG;
    payload: Partial<ValgState>;
}

export type ValgStateActionTypes = UpdateValgAction;
