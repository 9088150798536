import { RadioButtonValgModel } from '../../Shared/Scripts/Components/RadioButtonValg';
import VeilederFristerDto from '../Models/VeilederFristerDto';

const stipendOgLan = 'a1-stipendOgLan';
const betalingAvGjeld = 'a2-betalingAvGjeld';
const slettingAvRenter = 'a3-betalingAvGjeld';
const ordningerForSlettingAvGjeld = 'a4-betalingAvGjeld';

const aValg: RadioButtonValgModel<VeilederFristerDto>[] = [
    {
        id: 'frister-a1',
        value: stipendOgLan,
        tekst: 'ledetekstStipendOgLanSteg1',
    },
    {
        id: 'frister-a2',
        value: betalingAvGjeld,
        tekst: 'ledetekstBetalingAvGjeldSteg1',
    },
    {
        id: 'frister-a3',
        value: slettingAvRenter,
        tekst: 'ledetekstSlettingAvRenterSteg1',
    },
    {
        id: 'frister-a4',
        value: ordningerForSlettingAvGjeld,
        tekst: 'ledetekstSlettingGjeldSteg1',
    },
];

export default {
    a1_stipendOgLan: stipendOgLan,
    a2_betalingAvGjeld: betalingAvGjeld,
    a3_slettingAvRenter: slettingAvRenter,
    a4_ordningerForSlettingAvGjeld: ordningerForSlettingAvGjeld,
    aValg,
};