export const AppContainerId = 'veilederFrister';
export const BoksA = 'frister-boksA';
export const BoksBa = 'frister-boksBa';
export const BoksBb = 'frister-boksBb';
export const BoksBc = 'frister-boksBc';
export const BoksCa = 'frister-boksCa';
export const BoksCb = 'frister-boksCb';
export const BoksCc = 'frister-boksCc';
export const BoksCd = 'frister-boksCd';
export const BoksCe = 'frister-boksCe';
export const BoksCf = 'frister-boksCf';
export const BoksCg = 'frister-boksCg';
export const BoksCh = 'frister-boksCh';
export const BoksCi = 'frister-boksCi';
export const BoksCj = 'frister-boksCj';
export const BoksCk = 'frister-boksCk';
export const BoksCl = 'frister-boksCl';
export const BoksCm = 'frister-boksCm';
export const BoksCn = 'frister-boksCn';